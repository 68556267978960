import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


class About extends React.Component{   

    options = {
        loop: true,
        center: false,
        items: 2,
        margin: 0,
        autoplay: true,
        dots: false,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        margin: 40,
    };
    
    render(){
        return (
        <div>
                <div className="nextBG">
                    <iframe 
                    src="https://themebgylp.tedxiimbangalore.com/"
                    allowfullscreen></iframe>
                </div>

            <section className="about-us-countdown-area section-padding-100-0 about-section nextExplainArea" id="about">

                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-12">
                        <div className="about-content-text mb-80 \">
                            {/* <h6 className="wow fadeInUp" data-wow-delay="300ms">Tedxiimbangalore</h6> */}
                            <h3 className="wow fadeInUp " data-wow-delay="300ms">Nurturing the Ne<span className='theRed'>X</span>t</h3>
                            <p className="wow fadeInUp" data-wow-delay="300ms">
                                The theme "Nurturing the NeXt '' encapsulates the essence of relentless growth and innovation guided by learnings from the past. It reflects the idea that even after achieving success, great leaders and organisations must consistently nurture and empower themselves, and the next generation of innovators, thinkers, and doers to remain at the forefront of their fields and realise their full potential. 
                            </p>
                            <p className="wow fadeInUp" data-wow-delay="300ms">
                                This theme underscores the importance of mentorship, knowledge transfer, and a commitment to fostering an environment where new ideas can flourish. It encourages the belief that while it is important to explore what future's pandora box has for us, it is also important to keep past learnings close to our hearts while we cultivate new seeds of talent, ideas and innovation. 
                            </p>
                            <p className="wow fadeInUp" data-wow-delay="300ms">
                                With 50 glorious years of IIM Bangalore, we strengthen the idea of innovating and re innovating to stay at the forefront. With something new always bubbling up, our goal is to challenge and surprise ourselves with what we are capable of, to make the world a better place. Hence, at TEDxIIMBangalore, we aim to explore and celebrate the strategies and stories of those who have effectively nurtured themselves and organisations, fostering a legacy of innovation and excellence. Through our speakers and discussions, we will delve into the diverse ways in which these trailblazers met their ikigai, transformed organisations, and gave back to the society, and how they keep taking innovative paths to continue strong on the marathon named life.  
                            </p>
                            <p className="wow fadeInUp" data-wow-delay="300ms">
                                "Nurturing the NeXt" challenges us all to reflect on our role in shaping the future organisations and the future us, emphasising the responsibility and privilege of passing on knowledge, inspiration, and opportunities to the next generation, thus ensuring a legacy of enduring success.
                            </p>
                        
                        </div>
                        </div>


                    </div>
                </div>

               
            </section>
            





            







            
        </div>
        )
    };
}
export default About;